<template>
  <!-- 车辆添加 -->
  <div>
    <Modal
      :value="value"
      title="详情"
      @input="(val) => $emit('input', val)"
      :width="800"
      @on-visible-change="modalChange"
    >
      <Form :label-width="100">
        <Row>
          <Col span="12">
            <FormItem label="社会化组织名称：" :label-width="130">
              <span>{{ data.serviceName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="编码：">
              <span>{{ data.codeValue }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="统一社会信用代码" :label-width="130">
              <span>{{ data.creditCode }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="企业类型：" >
              <span>{{ data.enterpriseTypeName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="所属行业：" >
              <span>{{ data.industry }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="省市区编码：" >
              <span>{{ data.regionCodeName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="业务范围：">
              <span>{{ data.businessScopeName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="营业期限：">
              <span>{{ data.businessTerm }}</span>
            </FormItem>
          </Col>
          
          <Col span="12">
            <FormItem label="服务地址：">
              <span>{{ data.serviceAddress }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="联系人：">
              <span>{{ data.name }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="联系电话：">
              <span>{{ data.tel }}</span>
            </FormItem>
          </Col>
        </Row>
      </Form>
        <div class="equipment">设备设施列表</div>
        <Table height='120' :columns="facilityTable" :data="facilityTableData"></Table>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => this.$emit('input', false)">
          确定</Button
        >
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cooperationDetailInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      facilityTable:[
        {
            title:'车辆品牌',
            key:"carModel"
        },
        //车辆类型\车牌号/颜色\长宽高
        {
            title:'车辆类型',
            key:"carType"
        },
        {
            title:'车牌号',
            key:"plate"
        },
        {
            title:'车辆颜色',
            key:"carColor"
        },
        {
            title:'车辆厢长',
            key:"lasting"
        },
        {
            title:'车辆厢宽',
            key:"wide"
        },
        {
            title:'车辆厢高',
            key:"high"
        },
      ],
      facilityTableData:[
          {
            carModel:'',
            carType: '',
            plate: '',
            carColor: '',
            lasting: '',
            wide: '',
            high: '',
          }
      ]
    };
  },
  methods: {
    modalChange(validate) {
      this.data = this.cooperationDetailInfo;
      console.log(this.data);
      this.getFacility()
     
    },
    getFacility(){
        this.$post(this.$api.SOCIAL_MANAGE.FACILITY,{
            companyNo:this.data.companyNo,
        }).then((res)=>{
            this.facilityTableData=res.car
        })
    }
  },
  mounted(){
       
  }
};
</script>

<style lang="less" scoped>
.equipment{
    font-weight: 700;
    font-size: 20px;
    text-align: center;
}
</style>